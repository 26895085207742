import React, { useState, useEffect } from 'react';
import Chakrainfo from '../Assests/chakrainfo.png';
import Joybharath from '../Assests/joybharath.jpg';
import Algoworks from '../Assests/algoworks.png';

const NavBelowImages = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide === 0 ? 3 : prevSlide - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide === 3 ? 0 : prevSlide + 1));
  };

  const handleSlideTo = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide === 3 ? 0 : prevSlide + 1));
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="default-carousel" className="relative w-full h-full md:w-full h-full" data-carousel="slide">
      {/* Carousel wrapper */}
      <div className="relative h-full overflow-hidden rounded-lg md:h-full">
        {/* Carousel Items */}
        {[Chakrainfo, Joybharath,Algoworks, Joybharath].map((image, index) => (
          <div key={index} className={`duration-700 ease-in-out ${currentSlide === index ? '' : 'hidden'}`} data-carousel-item>
            <img src={image} className="absolute block w-full top-0 left-0 h-auto md:w-full md:h-full" alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      {/* Slider indicators */}
      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
        {[...Array(4).keys()].map((index) => (
          <button key={index} type="button" className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-white' : 'bg-gray-400'}`} aria-current={currentSlide === index ? 'true' : 'false'} aria-label={`Slide ${index + 1}`} onClick={() => handleSlideTo(index)} />
        ))}
      </div>
      {/* Slider controls */}
      <button type="button" className="absolute top-0 start-0  flex items-center justify-center h-full px-4 " data-carousel-prev onClick={handlePrevSlide}>
        <span className="inline-flex items-center justify-center w-10 h-10  dark:bg-gray-800/30   group-focus:outline-none">
          <svg className="w-4 h-4 text-black dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button type="button" className="absolute top-0 end-0  flex items-center justify-center h-full px-4 " data-carousel-next onClick={handleNextSlide}>
        <span className="inline-flex items-center justify-center w-10 h-10  dark:bg-gray-800/30 group-focus:outline-none ">
          <svg className="w-4 h-4  text-black dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default NavBelowImages;
