import React from 'react';

export default function Mission() {
  return (
    <section className='md:grid grid-cols-2 mt-16 p-10 text-center md:divide-x-2 '>
      <div>
        <div className="underline font-serif text-4xl font-normal mb-3">Mission</div>
        <div className="mb-5">
          We aim to inspire imagination, spark joy, and empower communities while maintaining a steadfast commitment to excellence, diversity, and sustainability.
        </div>
      </div>
      <div > {/* Add margin top to create a gap */}
        <div className="underline font-serif text-4xl font-normal mb-3">Vision</div>
        <div>
          To be a trailblazer at the intersection of entertainment, technology, and creativity, shaping cultural landscapes and redefining boundaries
        </div>
      </div>
    </section>
  );
}
