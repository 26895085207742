import React from 'react';
import { useLocation } from 'react-router-dom';
import AboutCard from './AboutCard';
import Mission from './Mission';

export default function About() {
  const location = useLocation();

  const isAboutPage = location.pathname === '/about';
  
  return (
    
      <>
      <div className='mt-14'>
        <section className="container grid gap-8 md:grid-cols-2">
          <div className="px-5 mt-10 md:content-center">
            <h1 className="text-4xl font-medium mb-2 text-center pt-5">About us</h1>
            <div className='text-left'>
              Chakra Group is in charge of the amazing job completed in every industry. Chakra Group seems to have a broad range of projects in the manufacturing, fintech, entertainment, and construction sectors, demonstrating its adaptability and aptitude in a variety of contexts.
            </div>
          </div>
          <div className='mt-20'>
            <video className='' controls>
              <source src="CompanyVideo.mp4" type='video/mp4'/>
            </video>
            </div>
        </section>
      </div>
       
       <div className='mt-10'>
       {isAboutPage  && <Mission/>}
       </div>
        {/* Render AboutCard component only on the /about page */}
        
        {isAboutPage  && <AboutCard />}
        
    </>
  );
}
