import React from 'react';
import About from './About';

import Footer from './Footer';
import WhyUs from './WhyUs';
import Tooltip from './Tooltip';
import NavBelowImages from './NavBelowImages';
import NewsContainer from './NewsContainer';
import Chairman from './Chairman';
import AboutCard from './AboutCard';
import ContentImage from './ContentImage';





export default function Home() {
  return (
    <div class="mt-14">
      <div className='md:grid grid-cols-2 md:h-full'>
        <div >
          <Chairman />
        </div>
        <div >
          <NavBelowImages />
        </div>

      </div>
      <ContentImage/>
      <div>
        <About />
      </div>
      
      
      <div className='container mt-10 mb-10'>
        <WhyUs />
      </div>
      <div>
        <NewsContainer />
      </div>
      {/* Footer Component */}
       <div className='w-full '>

        <Tooltip />
      </div>
    </div>
  )
}


