import React, { useState } from 'react';
import Axios from "axios";
import { faYoutube, faInstagram, faLinkedin, faFacebook, faXTwitter,faWebflow } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const url = "https://www.chakragroup.in/insert.php";
    const [data, setData] = useState({
        name: "",
        mobile: "",
        email: "",
        message: ""

    });

    function submitForm(e) {
        e.preventDefault();

        // Check if all fields are filled out
        //   if (!data.name || !data.mobile || !data.message) {
        //     setErrorMessage("Please fill out all fields");
        //     return;
        //   }

        // Disable the submit button to prevent duplicate submissions
        setIsSubmitting(true);

        Axios.post(url, {
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            message: data.message
        })
            .then(res => {
                console.log(res.data);
                setSuccessMessage("We will contact you soon..! Thank You");
                setErrorMessage("");
                // Clear form fields after successful submission
                setData({ name: "", mobile: "", email: "", message: "" });
            })
            .catch(error => {
                console.error("Error:", error);
                setErrorMessage("An error occurred. Please try again later.");
                setSuccessMessage("");
            })
            .finally(() => {
                // Enable the submit button after the request is completed
                setIsSubmitting(false);
            });
    }

    function handleChange(e) {
        const { id, value } = e.target;
        setData({ ...data, [id]: value });
    }

 

    return (
        <div>

            <section className="bg-blue-50 dark:bg-slate-800" id="contact">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">


                    <div className="mb-4">
                        <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                                Get in Touch
                            </h2>
                        </div>
                    </div>
                    <div className="flex items-stretch justify-center">
                        <div className="grid md:grid-cols-2">
                            <div className="h-full pr-6">
                                <div className="mt-3 mb-12 text-lg text-gray-600 dark:text-slate-400">
                                    We'd love to hear from you..!!
                                    Feel free to reach out with any queries, Feedbacks and partnership
                                    opportunities.
                                </div>
                                <ul className="mb-6 md:mb-0">
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" className="h-6 w-6">
                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                                <path
                                                    d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Meet Us
                                            </h3>
                                            <div className="text-gray-600 dark:text-slate-400"> 403, Alcazar Plaza, Rd Number 1,
                                                Banjara Hills</div>
                                            <div className="text-gray-600 dark:text-slate-400">Hyderabad, Telangana</div>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" className="h-6 w-6">
                                                <path
                                                    d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                                </path>
                                                <path d="M15 7a2 2 0 0 1 2 2"></path>
                                                <path d="M15 3a6 6 0 0 1 6 6"></path>
                                            </svg>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Call Us
                                            </h3>
                                            <div className="text-gray-600 dark:text-slate-400">Mobile: +91 8465959211</div>

                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <FontAwesomeIcon icon={faWebflow}/>

                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">Visit Us</h3>
                                            <li> <a href="https://www.chakrainfotainment.com/" className="text-gray-600 dark:text-slate-400">chakra Infotainment</a></li>
                                            <li><a href="https://www.joybharath.com/" className="text-gray-600 dark:text-slate-400" >Joy Bharath</a></li>
                                            <li><a href="https://www.amigosdolls.com/" className="text-gray-600 dark:text-slate-400" >Amigos Dolls</a></li>
                                            <li> <a href="https://www.algoworks.co.in/" className="text-gray-600 dark:text-slate-400" >Algo Works</a></li>
                                        </div>
                                    </li>
                                    <div className='mt-3  mx-14 text-lg font-medium leading-6 text-gray-900'>Follow Us On</div>
                                    <ul className="flex mb-4 md:order-1   md:mb-0 mt-2 lg:-mx-0">

                                        <li className='mx-1'>
                                            <a href="https://twitter.com/your_account_username" className="flex justify-center items-center    " aria-label="Twitter">
                                                <FontAwesomeIcon icon={faXTwitter} size="2x" style={{ color: ' #000000' }} />
                                            </a>
                                        </li>
                                        <li className="gap-1 mx-1">
                                            <a href="https://www.youtube.com/@chakrainfotainment6507" className="flex justify-center items-center " aria-label="YouTube">
                                                <FontAwesomeIcon icon={faYoutube} size="2x" style={{ color: '#FF0000' }} />
                                            </a>
                                        </li>
                                        <li className='gap-1 mx-1'>
                                            <a href="https://www.instagram.com/chakrainfotainment" className="flex justify-center items-center " aria-label="Instagram">
                                                <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#C13584' }} />
                                            </a>
                                        </li>
                                        <li className="mx-1">
                                            <a href="#0" className="flex justify-center items-center ">
                                                <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: '#0077B5' }} />
                                            </a>
                                        </li>
                                        <li className="mx-1">
                                            <a href="#0" className="flex justify-center items-center">
                                                <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#1877F2' }} />
                                            </a>
                                        </li>

                                    </ul>

                                </ul>
                            </div>
                            <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h2 className="mb-4 text-2xl font-bold dark:text-white">Write Us</h2>
                                    <div className="flex justify-center items-center">
                                        {successMessage && (
                                            <div className="bg-blue-500 text-white text-center mx-auto p-3 rounded mb-4 inline-block">
                                                {successMessage}
                                            </div>
                                        )}
                                        {errorMessage && (
                                            <div className="bg-blue-200 text-red-800 p-2 rounded mb-4 inline-block">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                   
                                </div>

                                <form method="post" onSubmit={submitForm}>

                                    <div className="mb-6 mt-3">
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <input type="text" id="name" autoComplete="given-name" placeholder="Your name" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" name="name" value={data.name} onChange={handleChange} required />
                                            <input type="text" id="mobile" autoComplete="tel" placeholder="Mobile Number" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" name="mobile" value={data.mobile} onChange={handleChange} required />
                                            <input type="email" id="email" autoComplete="email" placeholder="Your email address" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" name="email" value={data.email} onChange={handleChange} required />
                                        </div>
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <textarea id="message" name="message" cols="30" rows="5" placeholder="Write your message..." className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0 resize-none" value={data.message} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md  sm:mb-0">
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Contact;
