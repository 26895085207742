import React, { useState, useEffect, useRef } from 'react';
import JoyBharath from '../Assests/joybharath.jpeg';
import Chakrainfo from '../Assests/chakrainfo.png';
import Algoworks from '../Assests/algoworks.png';
export default function ContentImage() {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target); // Stop observing after it becomes visible
                    }
                });
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);
    return (
        <div className='mt-20'>
            <h1 className='text-center'>OUR COMPANIES</h1>
            <div className='justify center'>
                <div className="flex flex-col md:flex-row p-3 ">

                    <div className="w-full  md:w-1/2  lg:mt-10 p-3 lg:ml-14 lg:text-lg  text-sm">
                        <h3 className='text-center mb-3'>Joy Bharath</h3>
                        Your Next Tech Innovation Hub in Banjara Hills! Get ready to embark on a journey of innovation and collaboration with our new startup venture, AlgosWork. Nestled in the vibrant Banjara Hills district, we're here to revolutionize the tech landscape with cutting-edge solutions and boundless creativity. Stay tuned for updates as we pave the way for a brighter, tech-driven future!
                        <div>
                            <a href="https://www.joybharath.com/" rel="noopener noreferrer">
                                <button className="mt-3 p-2 text-blue  rounded border md: hover:bg-blue-100  transition duration-200 ">
                                    Read More
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex justify-center">
                        <a href="https://www.joybharath.com/" rel="noopener noreferrer" className="block">
                            <img
                                ref={imageRef}
                                src={JoyBharath}
                                alt="Innovation Hub"
                                className={`w-full lg:h-96 md:h-auto   rounded-lg transition duration-300 ease-in-out transform ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
                            />
                        </a>
                    </div>

                </div>
                <div className="flex flex-col md:flex-row p-3 ">
                    <div className="w-full md:w-1/2 p-4 flex justify-center ">
                        <a href="https://www.algoworks.co.in/" rel="noopener noreferrer" className="block">
                            <img
                                ref={imageRef}
                                src={Algoworks}
                                alt="Innovation Hub"
                                className={`w-full lg:h-96 md:h-auto   rounded-lg transition duration-200 ease-in-out transform ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
                            />
                        </a>
                    </div>

                    <div className="w-full  md:w-1/2  lg:mt-10 p-3 lg:ml-14 lg:text-lg  text-sm ">
                        <h3 className='text-center  mb-3'>Algos Work</h3>
                        Your Next Tech Innovation Hub in Banjara Hills! Get ready to embark on a journey of innovation and collaboration with our new startup venture, AlgosWork. Nestled in the vibrant Banjara Hills district, we're here to revolutionize the tech landscape with cutting-edge solutions and boundless creativity. Stay tuned for updates as we pave the way for a brighter, tech-driven future!
                        <div className='order-2'>
                            <a href="https://www.algoworks.co.in/" rel="noopener noreferrer">
                                <button className="mt-3 p-2 text-blue  rounded border md: hover:bg-blue-100  transition duration-300 ">
                                    Read More
                                </button>
                            </a>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col md:flex-row p-3 ">

                    <div className="w-full  md:w-1/2  lg:mt-10 p-3 lg:ml-14 lg:text-lg  text-sm">
                        <h3 className='text-center mb-3'>CHAKRA INFOTAINMENT</h3>
                        Your Next Tech Innovation Hub in Banjara Hills! Get ready to embark on a journey of innovation and collaboration with our new startup venture, AlgosWork. Nestled in the vibrant Banjara Hills district, we're here to revolutionize the tech landscape with cutting-edge solutions and boundless creativity. Stay tuned for updates as we pave the way for a brighter, tech-driven future!
                        <div>
                            <a href="https://chakrainfotainment.com" rel="noopener noreferrer">
                                <button className="mt-3 p-2 text-blue  rounded border md: hover:bg-blue-100  transition duration-300 ">
                                    Read More
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex justify-center">
                        <a href="https://chakrainfotainment.com" rel="noopener noreferrer" className="block">
                            <img

                                src={Chakrainfo}
                                alt="Innovation Hub"
                                className={`w-full lg:h-96 md:h-auto   rounded-lg transition duration-300 ease-in-out `}
                            />
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}