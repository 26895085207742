import React from 'react';

const WhyUs = () => {
  return (
    <div className="container mx-auto  bg-blue-100 shadow-md rounded-md text-center mb-20 p-24">
      <h1 className="text-3xl font-semibold mb-4 underline">Why Us</h1>
      <div className="flex flex-col items-center">
        <span className="text-lg leading-relaxed whitespace-normal lg:w-2/3 xl:w-1/2 text-center">
          As the prestigious and leading company, we are dedicated to delivering quality products to our customers without delay. This ensures the growth of our company's standards.
        </span>
      </div>
    </div>
  );
};

export default WhyUs;
