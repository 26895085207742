import React from 'react';
import { faYoutube, faInstagram, faLinkedin, faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
      <footer className='relative bg-blue-950'>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

            {/* 1st block */}
            <div className="sm:col-span-12 lg:col-span-3">
              <div className="text-sm text-white">
                <ul>
                  <li className=' text-3xl -mt-3 -mx-8'>Chakra Group</li>
                  <li className=' mt-3 -mx-8 flex items-center'>
                    <svg className="w-8 h-8 fill-current mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                    </svg>
                    <span className='-mx-2'>403, Alcazar Plaza, Road No.1, </span>
                  </li>
                  <li className='mx-0'>Banjarahills, Hyderabad - 500034, Telangana .</li>
                  <div className='mt-3 text-lg -mx-5'>Follow Us On</div>
                  <ul className="flex mb-4 md:order-1 -ml-12  md:mb-0 mt-2 lg:-mx-12">
                    <li className='mx-1'>
                      <a href="https://twitter.com/Chakrainfotain?t=m5zhh141lZSyJlREI6aZNA&s=08" target="_blank" className="flex justify-center items-center " aria-label="Twitter">
                        <FontAwesomeIcon icon={faXTwitter} size="2x" style={{ color: '#FFFFFF' }} />
                      </a>
                    </li>
                    <li className="gap-1 mx-1">
                      <a href="https://www.youtube.com/@chakrainfotainment6507" target="_blank" className="flex justify-center items-center" aria-label="YouTube">
                        <FontAwesomeIcon icon={faYoutube} size="2x" style={{ color: '#FF0000' }} />
                      </a>

                    </li>
                    <li className='gap-1 mx-1'>
                      <a href="https://www.instagram.com/chakrainfotainment" target="_blank" className="flex justify-center items-center " aria-label="Instagram">       <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#C13584' }} />
                      </a>
                    </li>
                    <li className="mx-1">
                      <a href="" target='_blank' className="flex justify-center items-center ">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: '#0077B5' }} />
                      </a>
                    </li>
                    <li className="mx-1">
                      <a href="https://www.facebook.com/search/top?q=chakra%20infotainment" target="_blank" className="flex justify-center items-center ">
                        <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#1877F2' }} />
                      </a>
                    </li>
                  </ul>

                </ul>
              </div>
            </div>

            {/* 2nd block */}
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
              <h6 className="text-white font-medium mb-2">Products</h6>
              <ul className="text-sm -mx-8">
                <li className="mb-2">
                  <a href="https://www.chakrainfotainment.com/" className="text-white hover:text-black transition duration-150 ease-in-out no-underline hover:underl">Chakra Infotainment</a>
                </li>
                <li className="mb-2">
                  <a href="https://www.amigosdolls.com/" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Amigos Dolls</a>
                </li>
                <li className="mb-2">
                  <a href="https://www.algoworks.co.in/" className="text-white hoverxt-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Algo Works</a>
                </li>
                <li className="mb-2">
                  <a href="https://www.joybharath.com/" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Joy Bharath</a>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
              <h6 className="text-white font-medium mb-2">Services</h6>
              <ul className="text-sm -mx-8">
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Documentation</a>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Guides</a>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Jobs</a>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Support Center</a>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Partners</a>
                </li>
              </ul>
            </div>

            {/* 4th block */}
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
              <h6 className="text-white font-medium mb-2">Company</h6>
              <ul className="text-sm -mx-8">
                <li className="mb-2">
                  <a href="/" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Home</a>
                </li>
                <li className="mb-2">
                  <Link to="/about" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">About us</Link>
                </li>
                <li className="mb-2">
                  <Link to="/contact" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Contact Us</Link>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Pricing</a>
                </li>
                <li className="mb-2">
                  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline hover:underl">Privacy Policy</a>
                </li>
              </ul>
            </div>

            {/* 5th block */}
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
              <h6 className="text-white font-medium mb-2">Subscribe</h6>
              <p className="text-sm text-white mb-4">Get the latest news and articles to your inbox every month.</p>
              <form>
                <div className="flex flex-wrap mb-4">
                  <div className="w-full">
                    <label className="block text-sm sr-only" htmlFor="newsletter">Email</label>
                    <div className="relative flex items-center max-w-xs">
                      <input id="newsletter" type="email" className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm border border-black" placeholder="Your email" required />
                      <button type="submit" className="absolute inset-0 left-auto" aria-label="Subscribe">
                        <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                        <svg className="w-3 h-3 fill-current text-black mx-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form><div className='border'>
              <div className="text-white font-bold text-xl p-4"> Visitor Count  :  512 +</div>
              </div>
            </div>

          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t text-center border-gray-200">
            <div className="text-sm text-white items-center">&copy; Chakra Group. All rights reserved. - 2024 | <a href="#0" className="text-white hover:text-gray-900  transition duration-150 ease-in-out no-underline mx-70">Terms</a>  |  <a href="#0" className="text-white hover:text-gray-900 transition duration-150 ease-in-out no-underline"> Privacy Policy</a></div>
          </div>

        </div>
      </footer>
    
  );
};

export default Footer;
