import React from 'react';

const Card = ({ imageUrl, title, Name, content }) => {
  return (
    <div className='card-container '>
      <div className="rounded overflow-hidden border  max-w-xs mx-auto hover:shadow-lg mb-10">
        <img className="h-28 w-28 rounded-full mx-auto mt-6" src={imageUrl} alt={title} />
        <div className="px-6 py-4 h-80 mb-3">
          <div className="font-bold text-xl mb-2 text-center">{title}</div>
          <div className="font-bold text-xl mb-2 text-center">{Name}</div>
          <p className="text-gray-700 text-center ">{content}</p>
        </div>
      </div>
    </div>
  );
};

const AboutCard = () => {
  const cards = [
   
    {
      id: 1,
      imageUrl: 'https://m.media-amazon.com/images/M/MV5BOTdkOGM1MzYtNWRmMy00MTQxLTliMDQtY2RjMzMwMjk3YjFlXkEyXkFqcGdeQXVyMTUwNzQzNzUz._V1_.jpg',
      title: 'Director',
      Name: 'Venkata Ratnam Dhaveji',
      content: 'Our journey at Chakra has been one of continuous exploration and evolution. From producing audio content filled with divinity to crafting thought-provoking dramas in video format. Our dedication to excellence and our passion for creativity drive us to push boundaries and challenge the status quo in the industry.',
    },
    {
      id: 2,
      imageUrl: 'https://www.f6s.com/content-resource/profiles/952453_th1.jpg',
      title: 'Director',
      Name: 'Rakesh Aryasomayajula',
      content: 'At our company, we weave cultural heritage into every stitch, fostering pride and connection. With each doll, we share stories that resonate across generations, bridging diverse traditions. Join us in our mission to spread love, unity, and cultural appreciation through our cherished creations.',
    },
    
    
    {
      id: 3,
      imageUrl: 'https://mdbcdn.b-cdn.net/img/new/avatars/6.jpg',
      title: 'Technical Lead',
      Name: 'Priyanka manthangi',
      content: " I'm immensely proud of what we've achieved together. Our company's success is a testament to your hard work, dedication, and innovative spirit. As we navigate the ever-changing landscape, let's remain agile and forward-thinking. Our commitment to excellence and integrity must never waver. ",
    },
  ];

  return (
    <div>
      <h1 className="text-3xl text-center font-semibold mb-10 mt-16">Meet Our Team</h1>
      <div className="grid grid-cols-1 md:grid-cols-3   gap-3">
        {cards.map(card => (
          <Card key={card.id} imageUrl={card.imageUrl} title={card.title} Name={card.Name} content={card.content} />
        ))}
      </div>
    </div>
  );
};

export default AboutCard;