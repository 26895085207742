import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../Assests/ChakraGroup.png';

function Navigation() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isBusinessDropdownOpen, setIsBusinessDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleBusinessDropdown = () => {
        setIsBusinessDropdownOpen(!isBusinessDropdownOpen);
    };

    useEffect(() => {
        const closeDropdownMenu = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsBusinessDropdownOpen(false);
            }
        };

        document.addEventListener('click', closeDropdownMenu);

        return () => {
            document.removeEventListener('click', closeDropdownMenu);
        };
    }, []);

    const handleDropdownButtonClick = (event) => {
        event.stopPropagation(); // Prevents the event from bubbling up to the document
        toggleBusinessDropdown();
    };

    return (
        <>
        <nav className="fixed top-0 z-50 p-2 border-b-2 w-full  overflow:hidden bg-white md:z-10 ">
            <div className="max-w-7xl mx-auto px-2 flex items-center justify-between">
                {/* LogoImage */}
                <Link to="/" className="flex items-center">
                    <img src={logoImage} alt="Logo" className="h-10 justify-center block md:hidden " />
                    {/* Display RallaLogo only on non-mobile views */}
                    <div className="hidden md:block ml-2">
                        <img src={logoImage} alt="chakraGroup Logo" className="h-10 mb-2 hidden md:block" />
                    </div>
                </Link>
                {/* FontAwesome icon for mobile view */}
                <div className="md:hidden">
                    <FontAwesomeIcon icon={faBars} className="text-black text-2xl" onClick={toggleMobileMenu} />
                </div>
                {/* Web navigation items (hidden in mobile view) */}
                <div className="hidden md:flex md:items-center ">
                    <Link to="/" className="text-black no-underline  hover:bg-slate-100 text-md hover:text-black px-3 py-2 rounded-md  not-italic font-medium">Home</Link>
                    
                    <div className="relative inline-block" ref={dropdownRef}>
                        <button onClick={handleDropdownButtonClick} className="text-black hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">Business</button>
                        {isBusinessDropdownOpen && (
                            <div className="origin-top-right absolute top-full -right-24 mt-2 w-56 rounded-md shadow-lg bg-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                    <a href="https://www.chakrainfotainment.com/" target="_blank"  className="block px-4 py-2 text-sm text-gray-950 no-underline  hover:bg-blue-950 rounded-md hover:text-white" role="menuitem" tabIndex="-1">Chakra Infotainment</a>
                                    <a href="https://www.joybharath.com/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 no-underline hover:bg-blue-950 rounded-md hover:text-white" role="menuitem" tabIndex="-1">Joy Bharath</a>
                                    <a href="https://www.amigosdolls.com/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 no-underline hover:bg-blue-950 rounded-md hover:text-white" role="menuitem" tabIndex="-1">Amigo Dolls</a>
                                    <a href="https://www.algoworks.co.in/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 no-underline  hover:bg-blue-950 rounded-md hover:text-white" role="menuitem" tabIndex="-1">Algo Works</a>
                                </div>
                            </div>
                        )}
                    </div>
                    <Link to="/about" className="text-black no-underline hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">About</Link>
                    <Link to="/contact" className="text-black no-underline hover:bg-slate-100 text-md hover:text-black px-3 py-2 space-x-4 rounded-md  not-italic  font-medium">Contact Us</Link>
                </div>
            </div>
            {/* Mobile menu items */}
            <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
                <div className="flex flex-col items-center bg-gray-50">
                    <Link to="/" className="text-black no-underline hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">Home</Link>
                    <div className="relative inline-block" ref={dropdownRef}>
                        <button onClick={handleDropdownButtonClick} className="text-black hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">Business</button>
                        {isBusinessDropdownOpen && (
                            <div className="origin-top-right absolute -right-24 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 z-10 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                    <a href="https://www.chakrainfotainment.com/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-950 hover:text-white rounded-md" role="menuitem" tabIndex="-1">Chakra Infotainment</a>
                                    <a href="https://www.joybharath.com/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-950 hover:text-white rounded-md" role="menuitem" tabIndex="-1">Joy Bharath</a>
                                    <a href="https://www.item3.com" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-950 hover:text-white rounded-md" role="menuitem" tabIndex="-1">Amigo Dolls</a>
                                    <a href="https://www.item4.com" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-950 hover:text-white rounded-md" role="menuitem" tabIndex="-1">Algo Works</a>
                                </div>
                            </div>
                        )}
                    </div>
                    <Link to="/about" className="text-black no-underline hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">About</Link>
                    <Link to="/contact" className="text-black no-underline hover:bg-slate-100 text-md hover:text-black px-3 py-2 rounded-md  not-italic  font-medium">Contact Us</Link>
                </div>
            </div>
        </nav>
        </>
    );
}

export default Navigation;