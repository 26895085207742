
import React from "react";

import NavBelowImages from "./NavBelowImages";

export default function Chairman() {

    return (
        

            <section className="p-5">
            
                    <div class="pl-8">


                        <div class="font-bold text-2xl mb-2"> Our Voice</div>
                        <div class="pr-6">
                            <div >Reflecting on our journey, I'm immensely proud of what we've achieved together. Our company's success is a testament to your hard work, dedication, and innovative spirit. As we navigate the ever-changing landscape, let's remain agile and forward-thinking.</div>
                            <div>Our commitment to excellence and integrity must never waver. Together, we can overcome challenges and seize new opportunities. Let's continue to prioritize sustainability, diversity, and community engagement.</div>
                            <div>These values are not just aspirations; they're integral to who we are as a company. Thank you for your unwavering commitment and passion. Together, we will write the next chapter of our success story. </div>
                            <div class="mt-10"> Warm regards,</div>
                            <div>Chakra Group</div>
                            <div>Director, Venkata Ratnam Dhaveji</div>
                        </div>


                    </div>
             
                

            </section>
        
    )

}