import React from 'react'

export default function Play() {
  return (
    <div >
         <div className='semi-bold text-5xl mt-4 font-semibold text-center mb-7'>Media Corner</div>
      <section className="mx-5 my-5 mt-0 md:grid md:grid-cols-3 text-center span-2">

   <article className="md:mr-5 p-10 mb-5 basis-full shadow-md rounded-lg bg-blue-50 span-4 dark:bg-indigo-800 dark:text-indigo-400 hover:bg-blue-200">
  <div className='text-2xl text-center text-bold mb-3 '>Introducing AlgosWork</div>
    <span className='justify-center '>Your Next Tech Innovation Hub in Banjara Hills! Get ready to embark on a journey of innovation and collaboration with our new startup venture,
     AlgosWork. Nestled in the vibrant Banjara Hills district, we're here to revolutionize the tech landscape with cutting-edge solutions and boundless creativity. 
     Stay tuned for updates as we pave the way for a brighter, tech-driven future!
  <div>
    <a href='https://www.amigosdolls.com/' className='text-center text-bold text-blue-400' target="_blank">Stay tuned</a></div></span>
  </article>

  <article className="md:mr-5 p-10 mb-5 hover basis-full shadow-md rounded-lg  bg-pink-50 span-4 dark:bg-indigo-800 dark:text-indigo-400 hover:bg-pink-200">
    <div className='text-2xl text-center text-bold mb-3'>New video Alert!</div>
    <span className='justify-center'> Check Out Our Latest Release on YouTube!
  <div>
    <a href='https://www.youtube.com/watch?v=FSNympOyihI' className=' text-center text-bold text-blue-400' target="_blank">Watch Our New Video!</a></div></span>

  </article>
  
  <article className="md:mr-5  mb-4 p-10 shadow-md basis-full rounded-lg bg-yellow-50 span-4 dark:bg-indigo-800 dark:text-indigo-400 hover:bg-yellow-200">
  <div className='text-2xl text-center text-bold mb-3  '>Amigos Dolls Await!!</div>
    <span className='text-center '> Launching Soon
  <div>
    <a href='https://www.amigosdolls.com/' className='text-center text-bold text-blue-400' target="_blank">Check Our Progress</a></div></span>

  </article>

  <article className="md:mr-5 mb-4 md:col-span-2 p-10  bg-orange-50 basis-full shadow-md rounded-lg span-4 dark:bg-indigo-800 dark:text-indigo-400 hover:bg-orange-200">
  <div className='text-2xl text-center text-bold mb-3'>Launching Joy Bharath</div>
    <span className='justify-center'>Your Next Tech Innovation Hub in Banjara Hills! Get ready to embark on a journey of innovation and collaboration with our new startup venture,
     AlgosWork. Nestled in the vibrant Banjara Hills district, we're here to revolutionize the tech landscape with cutting-edge solutions and boundless creativity. 
     Stay tuned for updates as we pave the way for a brighter, tech-driven future!
  <div>
    <a href='https://www.amigosdolls.com/' className=' text-center text-bold text-blue-400' target="_blank">Stay tuned</a></div></span>
   </article>

  <article className="md:mr-5  mb-5 basis-full shadow-md rounded-lg bg-blue-50 span-4 dark:bg-indigo-800 dark:text-indigo-400 hover:bg-blue-200">
  <div className='text-center semi-bold text-xl mb-2 mt-10 '>Alert</div>
  <span className='justify-center p-5'> Check Out Our Latest Release on YouTube!
  <div>
  <a href='https://www.youtube.com/watch?v=FSNympOyihI' className=' text-center text-bold  text-blue-400' target="_blank">Watch Our New Video!</a></div></span>
 </article>

 
</section>

    </div>
  )
}